import React from 'react';
// import './RestaurantForm.css';

const RestaurantForm = props => {
  var restaurant = props.state;
  var handleChange = props.handleChange;
  var handleCheckboxChange = props.handleCheckboxChange;
  var handleSave = props.handleSave;
  var handleDelete = props.handleDelete;
  var isCreate = props.isCreate;
  var showPlacesData = restaurant.is_places_data === 1;
  var placesData = restaurant.places_data;

  return (
    <div className="container mx-auto">
      <form onSubmit={handleSave.bind(this)} className="flex flex-col">
        <div className="flex flex-row items-center my-6">
          <div className="w-1/5 text-blue-600">
            <a href="/admin/restaurants">&larr; Back</a>
          </div>
          <div className="w-4/5">
            <h4>Enter or update information below and scroll down to Save</h4>
            <br />
          </div>
        </div>
        {!isCreate && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="id">Id</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={restaurant.id}
                disabled
              />
            </div>
          </div>
        )}
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="name">Name</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="name"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.name}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="name_japanese">Name (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="name_japanese"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.name_japanese}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="average_cost">Average Cost (¥)</label>
          </div>
          <div className="w-4/5">
            <input
              type="number"
              id="average_cost"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.average_cost}
              min="0"
              max="50000"
              step="500"
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="cuisine">Cuisine</label>
          </div>
          <div className="w-4/5">
            <input
              id="cuisine"
              className="w-full p-2 border rounded"
              type="text"
              value={restaurant.cuisine}
              onChange={handleChange}
              required
            ></input>
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="featured">Featured</label>
          </div>
          <div className="w-4/5">
            <label className="switch">
              <input
                id="featured"
                type="checkbox"
                onClick={handleCheckboxChange}
                checked={restaurant.featured}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="vegan">Vegan</label>
          </div>
          <div className="w-4/5">
            <label className="switch">
              <input
                id="vegan"
                type="checkbox"
                onClick={handleCheckboxChange}
                checked={restaurant.vegan}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="halal">Halal</label>
          </div>
          <div className="w-4/5">
            <label className="switch">
              <input
                id="halal"
                type="checkbox"
                onClick={handleCheckboxChange}
                checked={restaurant.halal}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="delivery">Delivery</label>
          </div>
          <div className="w-4/5">
            <label className="switch">
              <input
                id="delivery"
                type="checkbox"
                onClick={handleCheckboxChange}
                checked={restaurant.delivery}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="address">Address</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="address"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.address}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="address">Address (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="address_japanese"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.address_japanese}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="city">City</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="city"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.city}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="city">City (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="city_japanese"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.city_japanese}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="area">Area</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="area"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.area}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="area">Area (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="area_japanese"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.area_japanese}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="latitude">Latitude</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="latitude"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.latitude}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="area">Longitude</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="longitude"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.longitude}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="timings">Timings</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="holidays">Holidays</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="holidays"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.holidays}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="phone">Phone</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="phone"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.phone}
              required
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="email">Email</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="email"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.email}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="website">Website</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="website"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.website}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="facebook">Facebook</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="facebook"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.facebook}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="instagram">Instagram</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="instagram"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.instagram}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="cover_image_link">Cover Image Link</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="cover_image_link"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.cover_image_link}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="all_image_links">All Image Links</label>
            <br />
            *comma separated links
          </div>
          <div className="w-4/5">
            <textarea
              id="all_image_links"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              rows="5"
              cols="10"
              value={restaurant.all_image_links}
            ></textarea>
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="description">Description</label>
          </div>
          <div className="w-4/5">
            <textarea
              id="description"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              rows="5"
              cols="100"
              value={restaurant.description}
            ></textarea>
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label htmlFor="description_japanese">Description (Japanese)</label>
          </div>
          <div className="w-4/5">
            <textarea
              id="description_japanese"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              rows="5"
              cols="100"
              value={restaurant.description_japanese}
            ></textarea>
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Google Map URL</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="gmap_url"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.gmap_url}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Monday Timings Text (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_jp0"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_jp0}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Tuesday Timings Text (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_jp1"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_jp1}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Wednesday Timings Text (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_jp2"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_jp2}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Thursday Timings Text (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_jp3"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_jp3}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Friday Timings Text (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_jp4"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_jp4}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Saturday Timings Text (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_jp5"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_jp5}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Sunday Timings Text (Japanese)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_jp6"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_jp6}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Monday Timings Text (English)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_en0"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_en0}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Tuesday Timings Text (English)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_en1"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_en1}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Wednesday Timings Text (English)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_en2"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_en2}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Thursday Timings Text (English)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_en3"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_en3}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Friday Timings Text (English)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_en4"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_en4}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Saturday Timings Text (English)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_en5"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_en5}
            />
          </div>
        </div>
        <div className="flex flex-row items-center my-3">
          <div className="w-1/5">
            <label>Sunday Timings Text (English)</label>
          </div>
          <div className="w-4/5">
            <input
              type="text"
              id="timings_text_en6"
              className="w-full p-2 border rounded"
              onChange={handleChange}
              value={restaurant.timings_text_en6}
            />
          </div>
        </div>

        {/*Choose if places data should be used for this restaurant, this will modify the API response for this restaurant*/}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="Use_Places_Data">Use Places Data</label>
            </div>
            <div className="w-4/5">
              <label className="switch">
                <input
                  id="Use_Places_Data"
                  type="checkbox"
                  onClick={handleCheckboxChange}
                  checked={restaurant.Use_Places_Data}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>)}

        {/**Check Places data (cannot edit)*/}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <strong>Places Data (Check-only, not editable)&nbsp;</strong>
            <i>*This information is outdated as we cannot run the Places API script anymore, this information should be manually searched on Google and saved via the new fields added above.</i>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="Name_JP">Name in Japanese</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Name_JP}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="Name_EN">Name in English</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Name_JP}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="Address_JP">Address in Japanese</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Address_JP}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="Address_EN">Address in English</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Address_EN}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="Area_JP">Area in Japanese</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Area_JP}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="Area_EN">Area in English</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Area_EN}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="City_JP">City in Japanese</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.City_JP}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label htmlFor="City_EN">City in English</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.City_EN}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label>Timings in Japanese</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_JP0}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_JP1}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">

            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_JP2}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">

            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_JP3}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_JP4}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_JP5}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_JP6}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label>Timings in English</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_EN0}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_EN1}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">

            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_EN2}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">

            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_EN3}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_EN4}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_EN5}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Timings_Text_EN6}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label>Google Map URL</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.GMap_Url}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label>Website</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Website}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label>Latitude</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Latitude}
                disabled
              />
            </div>
          </div>)}
        {showPlacesData && (
          <div className="flex flex-row items-center my-3">
            <div className="w-1/5">
              <label>Longitude</label>
            </div>
            <div className="w-4/5">
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={placesData.Longitude}
                disabled
              />
            </div>
          </div>)}

        < div className="flex justify-center">
          <button type="submit" className="mr-6 px-6 py-3 text-primary-500 bg-transparent border border-solid border-primary-500 hover:bg-primary-500 hover:text-white font-bold cursor-pointer uppercase text-sm rounded outline-none focus:outline-none ease-linear transition-all duration-150">
            <span className="fa fa-floppy-o fa-lg" aria-hidden="true"></span>
            &nbsp;Save
          </button>

          {!isCreate && (
            <button
              type="button"
              className="px-6 py-3 text-white bg-primary-500 border border-solid border-primary-500 hover:bg-white hover:text-primary-500 font-bold cursor-pointer uppercase text-sm rounded outline-none focus:outline-none ease-linear transition-all duration-150"
              onClick={handleDelete.bind(this)}
            >
              <span className="fa fa-trash-o fa-lg" aria-hidden="true"></span>
              &nbsp;Delete
            </button>
          )}
        </div>
      </form>
    </div >
  );
};

export default RestaurantForm;
