import React, { Component } from 'react';
import intl from 'react-intl-universal';

import Main from './components/Main';
import GeneralNavbar from './components/nav/GeneralNavbar';

const detectBrowserLanguage = require('detect-browser-language');

const locales = {
  en: require('./locales/en-lang.json'),
  jp: require('./locales/jp-lang.json')
};

class App extends Component {
  state = {
    initDone: false
  };

  componentDidMount() {
    let chosenLocale = window.sessionStorage.getItem('app-locale');

    if (chosenLocale) {
      this.loadLocales(chosenLocale);
    } else {
      var browserLocale = detectBrowserLanguage();
      if (browserLocale.includes('en')) {
        this.loadLocales('en');
        window.sessionStorage.setItem('app-locale', 'en');
      } else {
        this.loadLocales('jp');
        window.sessionStorage.setItem('app-locale', 'jp');
      }
    }
  }

  loadLocales(chosenLocale) {
    intl
      .init({
        currentLocale: chosenLocale,
        locales
      })
      .then(() => {
        this.setState({ initDone: true });
      });
  }

  render() {
    return (
      this.state.initDone && (
        <div>
          <GeneralNavbar />
          <Main />
          <br />
        </div>
      )
    );
  }
}

export default App;
