export function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
}

//TODO : Fix firebaseUI localization properly
export function translateSignInTextHack() {
  setTimeout(() => {
    if (window.sessionStorage.getItem('app-locale') === 'jp') {
      let button = document.querySelector(
        `[data-provider-id="google.com"] .firebaseui-idp-text-long`
      );
      if (button) {
        button.innerHTML = `ログイン - Google`;
      }
      button = document.querySelector(
        `[data-provider-id="facebook.com"] .firebaseui-idp-text-long`
      );
      if (button) {
        button.innerHTML = `ログイン - Facebook`;
      }
    }
  }, 100);
}