import React, { Component } from 'react';
import $ from 'jquery';

// Tailwind Table component
import Table, { EditRestaurantCell, AvatarCell, StatusPill } from '../../Table';

//config
import { AppConfig } from '../../config';

//auth
import AuthBox from '../../components/AuthBox';
import firebaseApp from '../../components/AuthHelper';

const columns = [
  {
    Header: "Status",
    accessor: 'status',
    Cell: StatusPill
  },
  {
    Header: "Name",
    accessor: 'name',
    Cell: AvatarCell,
    imgAccessor: "cover_image_link"
  },
  {
    Header: "City",
    accessor: 'city'
  },
  {
    Header: "Area",
    accessor: 'area'
  },
  {
    Header: "Featured",
    accessor: 'featured',
    Cell: StatusPill
  },
  {
    Header: "Edit",
    accessor: 'id',
    Cell: EditRestaurantCell
  },
];

class RestaurantAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false,
      showAuthDialog: false,
      token: ''
    };

    this.loadAllRestaurants.bind(this);
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(user => {
        this.setState({
          isSignedIn: !!user,
          showAuthDialog: !this.state.isSignedIn
        });

        if (this.state.isSignedIn) {
          user
            .getIdTokenResult()
            .then(idTokenResult => {
              if (!idTokenResult.claims.admin) {
                this.setState({
                  isSignedIn: false
                });
                firebaseApp.auth().signOut();
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });

    this.loadAllRestaurants(this);
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  hideAlert = () => {
    this.setState({
      showAuthDialog: false
    });
  };

  loadAllRestaurants() {
    var url = AppConfig.restaurantUrl + '/api/read';

    $.ajax({
      url: url,
      type: 'GET',

      success: function (data) {
        this.setState({
          allList: data
        });
      }.bind(this),

      error: () => {
        console.log('Error loading the restaurants.');
      }
    });
  }

  render() {
    return (
      <div className="container mx-auto">
        {this.state.isSignedIn ? (
          <main>
            <div className="my-6">
              <h1 className="text-4xl text-center">Restaurant Management</h1>
            </div>
            <div className="mt-6 text-center">
              <a
                className="px-6 py-3 text-primary-500 bg-transparent border border-solid border-primary-500 hover:bg-primary-500 hover:text-white font-bold cursor-pointer uppercase text-sm rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                href="/admin/restaurants/add"
              >
                Add a new restaurant
              </a>
              <div className="mt-6">
                <h1 className="text-xl font-semibold">Manage Restaurants</h1>
              </div>
              <br />
            </div>
            <div className="mt-6">
              <Table columns={columns} data={this.state.allList} />
            </div>
          </main>
        ) : (
          <div className="centered-message">
            <span>
              Please sign in as an admin to access the admin screen(s)
            </span>
          </div>
        )}
        {this.state.showAuthDialog && (
          <AuthBox
            show={this.state.showAuthDialog}
            isSignedIn={this.state.isSignedIn}
            hideAlert={this.hideAlert}
            isAdmin={this.state.isSignedIn}
          />
        )}
      </div>
    );
  }
}

export default RestaurantAdmin;
