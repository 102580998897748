import React, { Component } from 'react';
import $ from 'jquery';
import Swal from 'sweetalert2';

import { AppConfig } from '../../config.js';

//form
import RestaurantForm from './RestaurantForm.js';

//auth
import firebaseApp from '../../components/AuthHelper';

class RestaurantCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false,
      token: '',
      name: '',
      name_japanese: '',
      average_cost: '',
      cuisine: '',
      featured: false,
      vegan: false,
      halal: false,
      delivery: false,
      timings: '',
      holidays: '',
      phone: '',
      email: '',
      website: '',
      facebook: '',
      instagram: '',
      latitude: '',
      longitude: '',
      description: '',
      description_japanese: '',
      cover_image_link: '',
      all_image_links: '',
      address: '',
      address_japanese: '',
      city: '',
      city_japanese: '',
      area: '',
      area_japanese: '',
      gmap_url: '',
      timings_text_jp0: '',
      timings_text_jp1: '',
      timings_text_jp2: '',
      timings_text_jp3: '',
      timings_text_jp4: '',
      timings_text_jp5: '',
      timings_text_jp6: '',
      timings_text_en0: '',
      timings_text_en1: '',
      timings_text_en2: '',
      timings_text_en3: '',
      timings_text_en4: '',
      timings_text_en5: '',
      timings_text_en6: ''
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(user => {
        this.setState({
          isSignedIn: !!user
        });

        if (!this.state.isSignedIn) {
          window.location.href = '/admin/restaurants';
        } else {
          user
            .getIdTokenResult()
            .then(idTokenResult => {
              if (!!idTokenResult.claims.admin) {
                this.setState({
                  token: idTokenResult.token
                });
              } else {
                window.location.href = '/admin/restaurants';
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCheckboxChange = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  };

  handleSave = event => {
    event.preventDefault();

    var url = AppConfig.restaurantUrl + '/api/add';
    var restaurant = this.state;

    Swal.fire({
      title: 'Are you sure?',
      text: 'This will add this restaurant to gocurry.jp !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!'
    }).then(result => {
      if (result.isConfirmed) {
        $.ajax({
          url: url,
          type: 'POST',
          headers: { Authorization: 'Bearer ' + this.state.token },
          contentType: 'application/json',
          data: JSON.stringify(restaurant)
        })
          .done(function () {
            Swal.fire('Saved!', 'Restaurant details are saved', 'success');
          })
          .fail(function (msg) {
            Swal.fire('Sorry!', 'Error creating new restaurant !', 'error');
            console.log('Error creating new restaurant :: ' + msg);
          });
      }
    });
  };

  handleDelete = () => { };

  render() {
    return (
      <RestaurantForm
        state={this.state}
        handleChange={this.handleChange}
        handleCheckboxChange={this.handleCheckboxChange}
        handleSave={this.handleSave}
        handleDelete={this.handleDelete}
        isCreate={true}
      />
    );
  }
}

export default RestaurantCreate;
