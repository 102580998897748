// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBSv-sHxO0y97WQttunqEYbdWAKwmcJ2bg",
  authDomain: "gocurry-prod.firebaseapp.com",
  projectId: "gocurry-prod",
  storageBucket: "gocurry-prod.appspot.com",
  messagingSenderId: "804711923591",
  appId: "1:804711923591:web:60e51f38b1cadf6030dd22",
  measurementId: "G-69DJFDPTM3",
};
