import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import firebase from 'firebase/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebaseApp from './AuthHelper.js';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
};

const AuthBox = (props) => {
  var show = props.show;
  var isSignedIn = props.isSignedIn;

  return (
    /*if not signed in ask to sign in or sign up*/
    isSignedIn !== undefined && !isSignedIn &&
    <SweetAlert
      title={''}
      onConfirm={() => props.hideAlert()}
      onCancel={() => props.hideAlert()}
      confirmBtnCssClass={'hidden'}
      allowEscape={true}
      closeOnClickOutside={true}
      showCloseButton={true}
      show={show}
    >
      <div>
        <div>
          <StyledFirebaseAuth
            className="firebaseUi"
            uiConfig={uiConfig}
            firebaseAuth={firebaseApp.auth()}
          />
        </div>
      </div>
    </SweetAlert>
  );
}

export default AuthBox;
