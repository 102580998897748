import React, { Component } from 'react';
import intl from 'react-intl-universal';

import AuthBox from '../AuthBox';

// Firebase Auth
import firebaseApp from '../AuthHelper';
import { translateSignInTextHack } from '../Helper';

class GeneralNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false,
      isAdmin: false,
      showAuthDialog: false,
      navbarOpen: false,
      token: ''
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(user => {
        this.setState({
          isSignedIn: !!user
        });
        if (this.state.isSignedIn) {
          user
            .getIdTokenResult()
            .then(idTokenResult => {
              this.setState({
                isAdmin: !!idTokenResult.claims.admin,
                token: idTokenResult.token
              });
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  hideAlert = () => {
    this.setState({
      showAuthDialog: false
    });
  };

  handleSignOut = () => {
    this.setState({
      isSignedIn: false,
      isAdmin: false,
      showAuthDialog: false
    })
    firebaseApp.auth().signOut();
    window.location.reload();
  };

  render() {
    return (
      <nav className="px-5 py-1 bg-primary-500 flex items-center justify-between shadow-md">
        <a href="/">
          <img
            src="/static/img/cover.png"
            alt="GoCurry"
            width="150"
            height="30"
          />
        </a>
        {this.state.isSignedIn ? (
          <div>
            <span
              className="cursor-pointer text-white font-bold"
              onClick={() =>
                this.setState({
                  navbarOpen: !this.state.navbarOpen
                })
              }
            >
              {firebaseApp.auth().currentUser.displayName} ▼
            </span>
            {this.state.navbarOpen && (
              <div className="absolute right-1 mt-2 bg-white shadow-xl">
                <div
                  className="block px-4 py-2 capitalize cursor-pointer text-gray-800 hover:bg-gray-100"
                  onClick={() => this.handleSignOut()}
                >
                  {intl.get('sign-out-label')}
                </div>
              </div>
            )}
          </div>
        ) : (
          <button
            className="bg-gray-300 text-gray-800 font-bold uppercase px-4 py-2 rounded-xl"
            onClick={() => {
              this.setState({
                showAuthDialog: true
              });
              translateSignInTextHack();
            }}
          >
            {intl.get('sign-in-label')}
          </button>
        )}
        <AuthBox
          show={this.state.showAuthDialog}
          isSignedIn={this.state.isSignedIn}
          isAdmin={false}
          hideAlert={this.hideAlert}
        />
      </nav>
    );
  }
}

export default GeneralNavbar;
