import React, { Component } from 'react';
import $ from 'jquery';
import Swal from 'sweetalert2';

import { AppConfig } from '../../config';
import { getUrlParameter } from '../../components/Helper';

//form
import RestaurantForm from './RestaurantForm';

//auth
import firebaseApp from '../../components/AuthHelper';

class RestaurantDetailAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false,
      token: '',
      id: '',
      name: '',
      name_japanese: '',
      average_cost: '',
      cuisine: '',
      featured: false,
      vegan: false,
      halal: false,
      delivery: false,
      timings: '',
      holidays: '',
      phone: '',
      email: '',
      website: '',
      facebook: '',
      instagram: '',
      latitude: '',
      longitude: '',
      cover_image_link: '',
      all_image_links: '',
      is_places_data: 0,
      use_places_data: 0,
      address: '',
      address_japanese: '',
      city: '',
      city_japanese: '',
      area: '',
      area_japanese: '',
      description: '',
      description_japanese: '',
      gmap_url: '',
      timings_text_jp0: '',
      timings_text_jp1: '',
      timings_text_jp2: '',
      timings_text_jp3: '',
      timings_text_jp4: '',
      timings_text_jp5: '',
      timings_text_jp6: '',
      timings_text_en0: '',
      timings_text_en1: '',
      timings_text_en2: '',
      timings_text_en3: '',
      timings_text_en4: '',
      timings_text_en5: '',
      timings_text_en6: '',
      places_data: null
    };

    this.loadRestaurantInfo.bind(this);
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(user => {
        this.setState({
          isSignedIn: !!user
        });

        if (!this.state.isSignedIn) {
          window.location.href = '/admin/restaurants';
        } else {
          user
            .getIdTokenResult()
            .then(idTokenResult => {
              if (!!idTokenResult.claims.admin) {
                this.setState({
                  token: idTokenResult.token
                });

                this.loadRestaurantInfo(this);
              } else {
                window.location.href = '/admin/restaurants';
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  loadRestaurantInfo() {
    var id = getUrlParameter('id');
    // fetch restaurants details from the admin API
    var url = AppConfig.restaurantUrl + '/api/admin/read/' + id;

    $.ajax({
      url: url,
      type: 'GET',

      success: function (data) {
        data = data[0];
        this.setState({
          id: data.id ? data.id : '',
          name: data.name ? data.name : '',
          name_japanese: data.name_japanese ? data.name_japanese : '',
          average_cost: data.average_cost ? data.average_cost : '',
          cuisine: data.cuisine ? data.cuisine : '',
          featured: data.featured === 1 ? true : false,
          vegan: data.vegan === 1 ? true : false,
          halal: data.halal === 1 ? true : false,
          delivery: data.delivery === 1 ? true : false,
          timings: data.timings ? data.timings : '',
          holidays: data.holidays ? data.holidays : '',
          phone: data.phone ? data.phone : '',
          email: data.email ? data.email : '',
          website: data.website ? data.website : '',
          facebook: data.facebook ? data.facebook : '',
          instagram: data.instagram ? data.instagram : '',
          address: data.address ? data.address : '',
          address_japanese: data.address_japanese ? data.address_japanese : '',
          latitude: data.latitude ? data.latitude : '',
          longitude: data.longitude ? data.longitude : '',
          city: data.city ? data.city : '',
          city_japanese: data.city_japanese ? data.city_japanese : '',
          area: data.area ? data.area : '',
          area_japanese: data.area_japanese ? data.area_japanese : '',
          description: data.description ? data.description : '',
          description_japanese: data.description_japanese
            ? data.description_japanese
            : '',
          cover_image_link: data.cover_image_link ? data.cover_image_link : '',
          all_image_links: data.all_image_links ? data.all_image_links : '',
          is_places_data: data.places_data ? 1 : 0,
          Use_Places_Data: data.places_data ? data.places_data.Use_Places_Data : 1,
          places_data: data.places_data,
          timings_text_jp0: data.timings_text_jp0 ? data.timings_text_jp0 : '',
          timings_text_jp1: data.timings_text_jp1 ? data.timings_text_jp1 : '',
          timings_text_jp2: data.timings_text_jp2 ? data.timings_text_jp2 : '',
          timings_text_jp3: data.timings_text_jp3 ? data.timings_text_jp3 : '',
          timings_text_jp4: data.timings_text_jp4 ? data.timings_text_jp4 : '',
          timings_text_jp5: data.timings_text_jp5 ? data.timings_text_jp5 : '',
          timings_text_jp6: data.timings_text_jp6 ? data.timings_text_jp6 : '',
          timings_text_en0: data.timings_text_en0 ? data.timings_text_en0 : '',
          timings_text_en1: data.timings_text_en1 ? data.timings_text_en1 : '',
          timings_text_en2: data.timings_text_en2 ? data.timings_text_en2 : '',
          timings_text_en3: data.timings_text_en3 ? data.timings_text_en3 : '',
          timings_text_en4: data.timings_text_en4 ? data.timings_text_en4 : '',
          timings_text_en5: data.timings_text_en5 ? data.timings_text_en5 : '',
          timings_text_en6: data.timings_text_en6 ? data.timings_text_en6 : '',
          gmap_url: data.gmap_url ? data.gmap_url : ''
        });
      }.bind(this),

      error: () => {
        console.log('Error loading restaurant details.');
      }
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCheckboxChange = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  };

  handleSave = event => {
    event.preventDefault();

    var url = AppConfig.restaurantUrl + '/api/update/' + this.state.id;
    var restaurant = this.state;

    Swal.fire({
      title: 'Are you sure?',
      text: 'This will change the restaurant info on gocurry.jp !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!'
    }).then(result => {
      if (result.isConfirmed) {
        $.ajax({
          url: url,
          type: 'PUT',
          headers: { Authorization: 'Bearer ' + this.state.token },
          contentType: 'application/json',
          data: JSON.stringify(restaurant)
        })
          .done(function () {
            Swal.fire('Saved!', 'Restaurant details are saved', 'success');
          })
          .fail(function (msg) {
            Swal.fire('Sorry!', 'Error updating restaurant details !', 'error');
            console.log('Error updating restaurant details :: ' + msg);
          });
      }
    });
  };

  handleDelete = () => {
    var url = AppConfig.restaurantUrl + '/api/delete/' + this.state.id;

    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete the restaurant from gocurry.jp !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        $.ajax({
          url: url,
          type: 'DELETE',
          headers: { Authorization: 'Bearer ' + this.state.token }
        })
          .done(function () {
            Swal.fire(
              'Deleted!',
              'Restaurant has been deleted from gocurry.jp !',
              'success'
            );
          })
          .fail(function (msg) {
            Swal.fire('Sorry!', 'Error deleting restaurant !', 'error');
            console.log('Error deleting restaurant :: ' + msg);
          });
      }
    });
  };

  render() {
    return (
      <RestaurantForm
        state={this.state}
        handleChange={this.handleChange}
        handleCheckboxChange={this.handleCheckboxChange}
        handleSave={this.handleSave}
        handleDelete={this.handleDelete}
        isCreate={false}
      />
    );
  }
}

export default RestaurantDetailAdmin;
