import React, { Component } from 'react';
import $ from 'jquery';

//Tailwind Table component
import Table, { RatingCell, JPDateCell } from '../../Table';

//config
import { AppConfig } from '../../config';

//auth
import AuthBox from '../../components/AuthBox';
import firebaseApp from '../../components/AuthHelper';

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//download CSV
import { CSVLink } from "react-csv";

const columns = [
  {
    Header: "Restaurant",
    accessor: 'restaurantName',
  },
  {
    Header: "Restaurant(JP)",
    accessor: 'restaurantNameJP',
  },
  {
    Header: "User",
    accessor: 'userName'
  },
  {
    Header: "Review Title",
    accessor: 'title',
  },
  {
    Header: "Review",
    accessor: 'review',
  },
  {
    Header: "Rating",
    accessor: 'rating',
    Cell: RatingCell,
  },
  {
    Header: "Language",
    accessor: 'language',
  },
  {
    Header: "CreateTime",
    accessor: 'createTime',
    Cell: JPDateCell,
  },
];

class ReviewAdmin extends Component {
  constructor(props) {
    super(props);

    var d = new Date(); // today
    var x = 5; // go back 7 days
    d.setDate(d.getDate() - x);

    this.state = {
      isSignedIn: false,
      showAuthDialog: false,
      allList: [],
      fromDate: d,
      toDate: new Date(),
    };

    this.loadReviews.bind(this);
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(user => {
        this.setState({
          isSignedIn: !!user,
          showAuthDialog: !this.state.isSignedIn
        });

        if (this.state.isSignedIn) {
          user
            .getIdTokenResult()
            .then(idTokenResult => {
              if (!idTokenResult.claims.admin) {
                this.setState({
                  isSignedIn: false
                });
                firebaseApp.auth().signOut();
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });

    this.loadReviews(this);
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  hideAlert = () => {
    this.setState({
      showAuthDialog: false
    });
  };

  loadReviews() {
    var url = AppConfig.restaurantUrl + '/api/reviews';
    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        "from": this.state.fromDate,
        "to": this.state.toDate,
      }),

      success: function (data) {
        this.setState({
          allList: data
        });
      }.bind(this),

      error: () => {
        console.log('Error loading the reviews.');
      }
    });
  }

  handleFromDateChange = (fromDate) => {
    this.setState({
      fromDate,
    });
  };

  handleToDateChange = (toDate) => {
    this.setState({
      toDate,
    });
  };

  render() {
    return (
      <div className="container mx-auto">
        {this.state.isSignedIn ? (
          <main>
            <div className="my-6">
              <h1 className="text-4xl text-center">Review Management</h1>
            </div>
            <div className="mt-6 text-center">
              <div className="mt-6">
                <h1 className="text-xl font-semibold">Manage Reviews</h1>
              </div>
              <br />
            </div>
            <div className="flex flex-row ...">
              <div className="w-72">
                From
                <DatePicker
                  className="text-xl border-2"
                  selected={this.state.fromDate}
                  onChange={this.handleFromDateChange} />
              </div>
              <div className="w-72">
                To
                <DatePicker
                  className="text-xl border-2"
                  selected={this.state.toDate}
                  onChange={this.handleToDateChange} />
              </div>
              <div className="w-72">
                <button
                  onClick={this.loadReviews.bind(this)}
                  className="mt-6 w-20 text-white rounded-lg bg-red-500 hover:bg-red-600">
                  Search
                </button>
              </div>
            </div>
            <div className="mt-6">
              <Table columns={columns} data={this.state.allList} />
            </div>
            {this.state.allList.length > 0 &&
              <CSVLink
                filename={"Reviews(+" + this.state.fromDate + "-" + this.state.toDate + ").csv"}
                className="text-red-400 text-xl text-center"
                data={this.state.allList}>Export to CSV
              </CSVLink>
            }
          </main>
        ) : (
          <div className="centered-message">
            <span>
              Please sign in as an admin to access the admin screen(s)
            </span>
          </div>
        )
        }
        {
          this.state.showAuthDialog && (
            <AuthBox
              show={this.state.showAuthDialog}
              isSignedIn={this.state.isSignedIn}
              hideAlert={this.hideAlert}
              isAdmin={this.state.isSignedIn}
            />
          )
        }
      </div >
    );
  }
}

export default ReviewAdmin;
